<template>
  <div :class="[positionClass]" class="grid place-content-center z-50 w-full h-full bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
      <LoadingSpinner/>
  </div>
</template>

<script>
import LoadingSpinner from '../spinners/LoadingSpinner.vue';
export default {
  components: {
    LoadingSpinner,
  },
  props: {
    positionClass: {
      type: String,
      default: 'absolute',
    },
  }
};
</script>

<style>

</style>