<template>
  <svg class="animate-spin" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13.25" cy="12.75" r="10.75" stroke="#F9CFE1" stroke-width="4"/>
    <path d="M24 12.75C24 6.81294 19.1871 2 13.25 2C7.31294 2 2.5 6.81294 2.5 12.75C2.5 18.6871 7.31294 23.5 13.25 23.5" stroke="#F42272" stroke-width="4" stroke-linecap="round"/>
  </svg>
</template>

<script>
</script>

<style>

</style>